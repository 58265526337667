<template>
  <div class="marvellous">
    <div class="marvebox">
      <h2>大赛直播</h2>
      <div class="span"></div>

      <div class="picbox" @click="gopicclick3()">
        <p>2024年全国总决赛-北京</p>
        <img src="../../assets/2023/banner.jpg" class="img1" />
        <div type="primary" class="btn">
          2024年全国总决赛开幕式及操作比赛、知识竞答<br />（５月２５日－２６日）
        </div>
      </div>
      <div class="picbox padtop" @click="gopicclick2()">
        <p>2023年郑州复赛开幕式及操作比赛、知识竞答</p>
      </div>
      <div class="picbox padtop" @click="gopicclick1()">
        <p>2023年呼和浩特复赛开幕式及操作比赛、知识竞答</p>
      </div>
    </div>
    <footerCard class="footer"></footerCard>
    <div class="goup">
      <a href="#">
        <i class="el-icon-caret-top"></i>
      </a>
    </div>
  </div>
</template>
<script>
import getShareInfo from "../../assets/js/share";
import footerCard from "../../components/footerCard.vue";
export default {
  data() {
    return {
      activeName: "2",
      loading: false,
      value: "点击加载更多",
      pageData: {
        page: 1,
        limit: 8,
        phase: localStorage.phase,
      },
      total: 0,
      marvellousList: [], //2022
      marvellousList2023: [], //2023
      srcList: [],
      disabled: false,
      picURL: "http://testgyyc.gytcare.com/",
      shareData: {
        title: "大赛直播",
        desc: "大赛开幕式及操作比赛、知识竞答",
        link: window.location.href,
        imgUrl: "https://jnds-static.oss-cn-beijing.aliyuncs.com/pcnavber.jpg",
      },
    };
  },
  created() {
    getShareInfo(this.shareData);
  },
  methods: {
    gopicclick4() {
      window.open("https://74b87f545e.live.polyv.cn/watch/4869311");
    },
    gopicclick3() {
      window.open("https://74b87f545e.live.polyv.cn/watch/4869478");
    },
    gopicclick1() {
      // window.open("https://74b87f545e.live.polyv.cn/watch/4353826");
    },
    gopicclick2() {
      // window.open("https://74b87f545e.live.polyv.cn/watch/4426697");
    },
  },
  components: {
    footerCard,
  },
};
</script>
<style scoped lang="scss">
.marvellous {
  .padtop {
    padding-top: 50px;
  }
  .picbox {
    text-align: center;
    cursor: pointer;
    position: relative;
    width: 80%;
    margin: auto;
    font-size: 20px;
    p {
      padding: 10px;
      background: #05258e;
      border-radius: 10px 10px 0 0;
      color: #fff;
      @media screen and (max-width: 520px) {
        font-size: 16px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 0 0 10px 10px;
    }
    .btn {
      border-radius: 0 0 10px 10px;
      background: rgb(0 0 0 / 60%);
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 5px;
      line-height: 1.5;
      padding: 10px 0;
      color: #fff;
      text-align: center;
      @media screen and (max-width: 520px) {
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  position: relative;
  ::v-deep .el-tabs__item {
    font-size: 20px !important;
    @media screen and (max-width: 520px) {
      font-size: 14px !important;
      width: 50%;
      text-align: center;
    }
  }
  ::v-deep .el-tabs__nav {
    @media screen and (max-width: 520px) {
      width: 100%;
    }
  }
  ::v-deep .el-tabs__item:hover {
    color: #05258e !important;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #05258e !important;
    font-weight: 600;
  }
  ::v-deep .el-tabs__active-bar {
    background: #05258e;
  }
  ::v-deep .el-image__error {
    background: #fff;
  }
  .goup {
    display: none;
    @media screen and (max-width: 520px) {
      display: block;
      position: fixed;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      bottom: 30px;
      right: 20px;
      background-color: #fff;
      box-shadow: 0 0 6px rgb(0 0 0 / 12%);
      border-radius: 50%;
      text-align: center;
      color: #409eff;
      font-size: 20px;
    }
  }
  .nodata {
    text-align: center;
    padding: 80px 0;
  }
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: #f9f9f9;
  padding-top: 84px;
  @media screen and (max-width: 520px) {
    // background: none;
    background-color: #fff;
    padding: 0;
  }
  h2 {
    font-size: 26px;
    text-align: center;
    color: #05258e;
    margin-bottom: 10px;
    @media screen and (max-width: 520px) {
      padding-top: 20px;
    }
  }
  .span {
    display: block;
    margin: 0px auto;
    width: 24px;
    height: 4px;
    background: #05258e;
    border-radius: 2px;
    margin-bottom: 38px;
    @media screen and (max-width: 520px) {
      margin-bottom: 18px;
    }
  }
  .tabs {
    width: 80%;
    margin: auto;
    font-size: 20px;
  }
  .mar-box {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    background-color: #fff;
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    padding-bottom: 40px;
    margin-bottom: 20px;
    position: relative;
    @media screen and (max-width: 520px) {
      width: 100%;
    }
    .footertitle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    .box {
      height: 220px;
      background-color: #fff;
      padding: 5px;
      flex-basis: 24%;
      text-align: center;
      overflow: hidden;
      box-sizing: border-box;
      @media screen and (max-width: 520px) {
        flex-basis: 50%;
        height: 100px;
      }
    }
  }
  .footer {
    display: block;
    margin-top: 100px;
  }
}
</style>
